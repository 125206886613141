import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { Tinode, Utilities } from '../model/tinode';
import { AuthenticationScheme } from '../model/tinode/constants';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { API_URLS } from '../const';
import moment from 'moment';
import { UserInterface } from '../model/user.interface';
import { Router } from '@angular/router';

const SERVER_URL = `${environment.chat}`;

@Injectable({
  providedIn: 'root',
})
export class TinodeService {
  users$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  tinode?: Tinode;
  timeoutReq: any;
  bufferedUsers: string[] = [];

  constructor(
    private userService: UserService,
    private api: ApiService,
    private router: Router
  ) {}

  public initConnection(): void {
    this.tinode = new Tinode('TinodeWeb/0.22', 'web', {
      APIKey: environment.apiTinodeKey,
      host: SERVER_URL,
      transport: 'ws',
      secure: true,
      autoReconnect: false,
    });
    this.tinode?.setAuthToken(Utilities.getObject('auth-token'));
    this.connect();
    this.tinode.onConnect.subscribe(_ => {
      this.login();
    });
    this.tinode.onDisconnect.subscribe(() => {
      console.log('Disconnected chat server');
    });
  }

  private connect() {
    this.tinode
      ?.connect()
      .then(() => {
        console.log('Connected to chat server');
      })
      .catch(err => {
        console.error('Connection error:', err);
      });
  }

  login() {
    if (!this.userService.getToken()) {
      return;
    }
    this.tinode
      ?.login(
        AuthenticationScheme.Rest,
        Utilities.base64encode(String(this.userService.getToken()))
      )
      .then(data => {
        Utilities.setObject('auth-token', {
          token: data.params.token,
          expires: data.params.expires,
        });
        this.subscribeMe();
      })
      .catch(() => {
        this.userService.logout();
        return this.router.navigate(['/login'], { skipLocationChange: true });
      });
  }

  subscribeMe() {
    this.api
      .post(API_URLS.tinodeUsers, {
        ids: [this.tinode?.getCurrentUserID()],
      })
      .subscribe({
        next: data => {
          this.userService.user$.next(data[0]);
          const topicMe = this.tinode?.getMeTopic();
          topicMe?.subscribe().then(() => {
            this.users$.next([this.userService.user$.value]);
          });
        },
      });
  }

  relativeDateFormat(then: moment.MomentInput) {
    return moment(then).calendar({
      sameDay: $localize`[Today]`,
      lastDay: $localize`[Yesterday]`,
      lastWeek: 'L',
      sameElse: 'L',
    });
  }

  getUsers(topics: string[]) {
    let a = 0;
    topics?.forEach((value: any) => {
      if (
        this.bufferedUsers.indexOf(value) === -1 &&
        this.users$.value.findIndex(
          (item: any) => item.tinode_uid === value
        ) === -1
      ) {
        a++;
        this.bufferedUsers.push(value);
      }
    });
    if (a === 0) {
      return;
    }
    clearTimeout(this.timeoutReq);
    this.timeoutReq = setTimeout(() => {
      if (this.bufferedUsers.length > 0) {
        this.updateUsers();
      }
    }, 100);
  }

  updateUsers() {
    this.api
      .post(API_URLS.tinodeUsers, {
        ids: this.bufferedUsers,
      })
      .subscribe({
        next: data => {
          this.users$.value.forEach((value: UserInterface) => {
            if (
              data.findIndex(
                (item: UserInterface) => value.user_id === item.user_id
              ) === -1
            ) {
              data.push(value);
            }
          });
          this.users$.next(data);
        },
      });
  }

  detectTypeMessage(msg: any) {
    if (typeof msg?.content === 'string') {
      return 'msg';
    }
    if (typeof msg?.content === 'object') {
      switch (msg.content?.tmp) {
        case 'reply':
          return 'reply';
        case 'draft':
          return 'draft';
        case 'attachments':
          return 'attachments';
        default:
          return 'error';
      }
    }
    return 'error';
  }

  sanitizeHTML(text: string) {
    return text.replace(/<[^>]*>/g, tag => {
      return this.escapeHTML(tag);
    });
  }

  escapeHTML(html: string) {
    const escapeMap: { [key: string]: string } = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
    };

    return html.replace(/[&<>"']/g, (match: string) => {
      return escapeMap[match];
    });
  }
}
